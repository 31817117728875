import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Feature } from "geojson";
import { FeatureSelector, IControl, LayerSpecification, SourceSpecification } from "mapbox-gl";
import { CoreoDataLineStyle, CoreoDataPointStyle, CoreoDataPolygonStyle, CoreoForm } from "../../types";
import AppDatabase from "../db/app-db.service";
import { projectFilePath } from "../db/filesystem.service";
import { cdnUrl } from "../image.service";

export const safelyRemoveFeatureState = (map: mapboxgl.Map, feature: FeatureSelector, state: string) => {
  const existing = map.getFeatureState(feature);
  if (existing && existing.hasOwnProperty(state)) {
    map.removeFeatureState(feature, state);
  }
}

export const safelyAddSource = (map: mapboxgl.Map, id: string, source: SourceSpecification, replace = false) => {
  // If the source already exists and we are not replacing it, return
  const existing = map.getSource(id);
  if (typeof existing !== 'undefined' && !replace) {
    return;
  }
  if (existing && replace) {
    map.removeSource(id);
  }
  map.addSource(id, source);
}

export const safelyAddLayer = (map: mapboxgl.Map, layer: LayerSpecification, replace = false) => {
  const existing = map.getLayer(layer.id);
  if (typeof existing !== 'undefined' && !replace) {
    return
  }
  if (existing && replace) {
    map.removeLayer(layer.id);
  }
  map.addLayer(layer);
}

export const safelyRemoveLayer = (map: mapboxgl.Map, id: string) => {
  if (map && typeof map.getLayer(id) !== 'undefined') {
    map.removeLayer(id);
  }
};

export const safelyRemoveSource = (map: mapboxgl.Map, id: string) => {
  if (map && typeof map.getSource(id) !== 'undefined') {
    map.removeSource(id);
  }
};

export const safelyMoveLayer = (map: mapboxgl.Map, id: string, beforeId?: string) => {
  if (map && typeof map.getLayer(id) !== 'undefined') {
    map.moveLayer(id, beforeId);
  }
}

export const safelyRemoveControl = (map: mapboxgl.Map, control: IControl) => {
  if (map?.hasControl(control)) {
    map.removeControl(control);
  }
}

export const safelySetZoomRange = (map: mapboxgl.Map, layerId: string, minZoom: number, maxZoom: number) => {
  if (map && typeof map.getLayer(layerId) !== 'undefined') {
    map.setLayerZoomRange(layerId, minZoom, maxZoom);
  }
}


export const getMapImageryUrl = async (projectId: number, source: string) => {

  if (!Capacitor.isNativePlatform()) {
    return cdnUrl(source);
  }
  return getMapSourceUrl(projectId, source);
}

export const getMapGeoJSONUrl = async (projectId: number, source: string) => {
  if (!Capacitor.isNativePlatform()) {
    return source;
  }
  return getMapSourceUrl(projectId, source);
}

const getMapSourceUrl = async (projectId: number, source: string) => {
  const url = new URL(source);
  const filename = url.pathname.split('/').pop();
  const path = projectFilePath(projectId, 'maps', filename);
  const result = await Filesystem.getUri({
    directory: Directory.Data,
    path
  });
  return Capacitor.convertFileSrc(result.uri);
}

export const polygonFillPaint = async (style: CoreoDataPolygonStyle): Promise<mapboxgl.FillLayerSpecification['paint']> => {
  const polygonFillPaint: mapboxgl.FillLayerSpecification['paint'] = {
    'fill-color': style.color,
    'fill-opacity': featureStateProperty(
      style.polygonOpacity,
      Math.min(style.polygonOpacity + 0.1, 1),
      Math.max(style.polygonOpacity - 0.1, 0.0),
      Math.min(style.polygonOpacity + 0.2, 1)
    )
  };

  if (style.styleAttributeId) {
    polygonFillPaint['fill-color'] = await getAttributeColorMatch(style.styleAttributeId, style.color);
  }

  return polygonFillPaint;
}

export const polygonBorderPaint = async (style: CoreoDataPolygonStyle): Promise<mapboxgl.LineLayerSpecification['paint']> => {
  return {
    'line-opacity': style.polygonBorderOpacity,
    'line-width': featureStateProperty(
      style.polygonBorderWidth,
      style.polygonBorderWidth + 2,
      style.polygonBorderWidth,
      style.polygonBorderWidth + 4
    ),
    'line-color': style.polygonBorderColor
  };
};

export const linePaint = async (style: CoreoDataLineStyle): Promise<mapboxgl.LineLayerSpecification['paint']> => {
  const linePaintStyle: mapboxgl.LinePaint = {
    'line-color': style.color,
    'line-width': featureStateProperty(
      style.lineWidth,
      style.lineWidth + 2,
      style.lineWidth,
      style.lineWidth + 4
    )
  };

  if (style.styleAttributeId) {
    linePaintStyle['line-color'] = await getAttributeColorMatch(style.styleAttributeId, style.color);
  }

  return linePaintStyle;
};

export const pointPaint = async (style: CoreoDataPointStyle): Promise<mapboxgl.CircleLayerSpecification['paint']> => {
  const pointPaintStyle: mapboxgl.CircleLayerSpecification['paint'] = {
    'circle-color': style.color,
    'circle-opacity': featureStateProperty(
      style.pointOpacity,
      Math.min(style.pointOpacity + 0.1, 1.0),
      Math.max(style.pointOpacity - 0.1, 0.0),
      Math.min(style.pointOpacity + 0.2, 1.0)
    ),
    'circle-radius': featureStateProperty(style.pointRadius, style.pointRadius + 5, style.pointRadius, style.pointRadius + 10),
    'circle-stroke-color': style.pointBorderColor,
    'circle-stroke-width': featureStateProperty(style.pointBorderWidth, style.pointBorderWidth + 2, style.pointBorderWidth, style.pointBorderWidth + 3),
    'circle-stroke-opacity': style.pointBorderOpacity
  };

  if (style.styleAttributeId) {
    pointPaintStyle['circle-color'] = await getAttributeColorMatch(style.styleAttributeId, style.color);
  }
  return pointPaintStyle;
};

export const featureStateProperty = (baseValue: any, selectedValue: any, deselectedValue: any, hoverValue: any): mapboxgl.ExpressionSpecification => {
  return [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    selectedValue,
    ['boolean', ['feature-state', 'deselected'], false],
    deselectedValue,
    ['boolean', ['feature-state', 'hover'], false],
    hoverValue,
    baseValue
  ]
};

export const getAttributeColorMatch = async (attributeId: number, color: string): Promise<mapboxgl.ExpressionSpecification> => {
  const attribute = await AppDatabase.instance.attributes.findById(attributeId);
  const items = await AppDatabase.instance.items.search(q => q.where('collectionId = ?', attribute.collectionId)).toArray();
  const colors = items.reduce((acc, item) => ([...acc, item.key, item.color ?? color]), []);
  return [
    'match',
    ['get', attribute.path],
    ...colors,
    color
  ];
}

export const formFeatureStyler = async (projectId: number, form: CoreoForm): Promise<(features: Feature[]) => Feature[]> => {

  const styleAttribute = form.styleAttributeId ? await AppDatabase.instance.attributes.findById(form.styleAttributeId) : undefined;
  const labelAttribute = form.labelAttributeId ? await AppDatabase.instance.attributes.findById(form.labelAttributeId) : undefined;
  const labelCollectionAttribute = form.labelCollectionAttributeId ? await AppDatabase.instance.attributes.findById(form.labelCollectionAttributeId) : undefined;

  if (!(styleAttribute || labelAttribute)) {
    return (features: Feature[]) => features;
  }

  const labelMap: Map<string, string> = new Map<string, string>();
  const iconMap: Map<string, string> = new Map<string, string>();
  const colorMap: Map<string, string> = new Map<string, string>();

  if (labelAttribute && labelAttribute.collectionId) {
    const items = await AppDatabase.instance.items.search(q => q.where('collectionId = ?', labelAttribute.collectionId)).toArray();
    for (const item of items) {
      labelMap.set(item.key, labelCollectionAttribute ? item.data[labelCollectionAttribute.path] : item.value);
    }
  }

  if (styleAttribute) {
    const items = await AppDatabase.instance.items.search(q => q.where('collectionId = ?', styleAttribute.collectionId)).toArray();
    for (const item of items) {
      if (item.icon) {
        iconMap.set(item.key, `${projectId}_${item.id}`);
      }
      if (item.color) {
        colorMap.set(item.key, item.color);
      }
    }
  }

  return (features: Feature[]) => features.map(feature => {
    const styleVal = feature.properties[styleAttribute?.path];
    const icon = (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint') && iconMap.has(styleVal) ? iconMap.get(styleVal) : undefined;
    const label = labelAttribute?.collectionId ? labelMap.get(feature.properties[labelAttribute.path]) : feature.properties[labelAttribute?.path];
    const color = colorMap.get(styleVal) ?? form.color;

    return {
      ...feature,
      properties: {
        ...feature.properties,
        '__label': label ?? '',
        '__color': color,
        '__icon': icon
      }
    };
  });
}
